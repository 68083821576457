import $ from 'jquery'

function initMainSlider(){
  if ($('.js-slider').length) {
    console.log('initMainSlider')
    $('.js-slider').slick({
      arrows: true,
      dots: true,
      prevArrow: '<button type="button" class="slick-prev"></button>',
      nextArrow: '<button type="button" class="slick-next"></button>',
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 600,
    });
  }
}

export default function initSlider(){
  $(document).ready( initMainSlider )
}
