import $ from 'jquery'
const $doc = $(document)

function expandContent(event) {
  let btn = $(this);
  let content = btn.prev('div');
  let wrap = btn.parent();
  let btnText = btn.find('.js-expandable-btn-text');

  event.preventDefault();

  wrap.toggleClass('expanded');
  content.slideToggle();

  if ( wrap.hasClass('expanded') ) {
    btn.attr('aria-expanded', 'true');
    btnText.html('Read less');
    content.attr('aria-hidden', 'false');
  } else {
    btn.attr('aria-expanded', 'false');
    btnText.html('Read more');
    content.attr('aria-hidden', 'true');
  }

}

export default function initExpandableContent(){
  $doc.on('click', '.js-expandable-content button', expandContent )
}