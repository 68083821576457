import $ from 'jquery'

const sliderWrapper = $('.js-testimonials-slider');

function initTestimonialsSlider(){
  if (sliderWrapper.length) {
    sliderWrapper.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      dots: true,
      autoplay: true,
      autoplaySpeed: 4000,
      speed: 600,
      nextArrow: '<button type="button" class="slick-next"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 52L26 27L2 2" stroke="#425050" stroke-width="4"/></svg></button>',
      prevArrow: '<button type="button" class="slick-prev"><svg width="29" height="54" viewBox="0 0 29 54" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M27 2L3 27L27 52" stroke="#425050" stroke-width="4"/></svg></button>',
    });
  }
}

export default function initTestimonials(){
  $(function() { initTestimonialsSlider() })
}
