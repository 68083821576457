import $ from 'jquery'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

// Simple Fade In animation
function animateSimple(elem) {
  gsap.to(elem, {
    duration: 0.5,
    y: 0,
    autoAlpha: 1,
    ease: "power1.out",
    stagger: 0.2,
    delay: 0.3,
    overwrite: 'auto'
  })
}

// Simple Fade In with more delay for first elem on the page (Hero)
function animateFirstElem(elem) {
  gsap.to(elem, {
    duration: 0.8,
    y: 0,
    autoAlpha: 1,
    ease: "power2.inOut",
    stagger: 0.5,
    delay: 0.8,
    overwrite: 'auto'
  })
}

// Set-ups for Animations
function setupFadeInElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  const anim = gsap.to(elem, {duration: 1, autoAlpha: 1, y: 0, delay: 0.3, paused: true});
  ScrollTrigger.create({
    trigger: elem,
    end: "top 100px",
    once: true,
    onEnter: self => {
      // If it's scrolled past, set the state
      // If it's scrolled to, play it
      self.progress === 1 ? anim.progress(1) : anim.play()
    },
  });
}
function setupSlideInLeftElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  const anim = gsap.to(elem, { duration: 1.5, ease: 'power1.out', autoAlpha: 1, y: 0, x: 0, delay: 0.3, paused: true });
  ScrollTrigger.create({
    trigger: elem,
    end: "top 100px",
    once: true,
    onEnter: self => {
      anim.play()
    },
  });
}
function setupSlideInRightElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  const anim = gsap.to(elem, { duration: 1.5, ease: 'power1.out', autoAlpha: 1, y: 0, x: 0, delay: 0.3, paused: true });
  ScrollTrigger.create({
    trigger: elem,
    end: "top 100px",
    once: true,
    onEnter: self => {
      anim.play()
    },
  });
}
function setupSlideInUpElement(elem) {
  gsap.set(elem, { autoAlpha: 0 })
  const anim = gsap.to(elem, { duration: 1.5, ease: 'power1.out', autoAlpha: 1, y: 0, x: 0, delay: 0, paused: true });
  ScrollTrigger.create({
    trigger: elem,
    once: true,
    onEnter: self => {
      anim.play()
    },
  });
}
function setupVerticalScroll(elem) {
  if ($(window).width() > 960) {
    $(".js-vertical-scroll-section").addClass('active');
    gsap.utils.toArray(".js-vertical-scroll-section").forEach(section => {
      let tl = gsap.timeline({
        scrollTrigger: {
          trigger: section,
          start: "top top",
          end: () => "+=100%",
          scrub: 1,
          pin: true,
        },
        defaults: { ease: "none" },
      });

      const panels = gsap.utils.toArray('.js-vertical-scroll-item', section);

      panels.forEach((panel, i) => {
        tl.fromTo(panel, {
          yPercent: i ? 100 : 0
        }, {
          yPercent: i === panels.length - 1 ? 0 : -100,
          duration: i === 0 || i === panels.length - 1 ? 0.5 : 1,
          ease: 'power1.out',
        }, "-=0.5");
      });

    });
  }

}
function setupFadeInFirstElement(elem) {
  hide(elem) // assure that the element is hidden when scrolled into view
  ScrollTrigger.create({
    trigger: elem,
    onEnter: function() { animateSimple(elem) }
  })
}
function setupFadeInStaggerElements() {
  gsap.utils.toArray(".js-fadeinStagger").forEach((elem, i) => {
    gsap.set(elem, {opacity:0})
    gsap.from(elem, {
      y: 10,
      ease: "power2.inOut",
      scrollTrigger: {
        trigger: elem,
        start: "top 100%"
      }
    });
  });
  ScrollTrigger.batch(".js-fadeinStagger", {
    onEnter: batch => gsap.to(batch, {opacity: 1, y: 0, ease: "power2.inOut",stagger: {each: 0.15, grid: [1, 3]}, overwrite: true}),
    onLeaveBack: batch => gsap.set(batch, {opacity: 0, y: 10, overwrite: true})
  });
  ScrollTrigger.addEventListener("refreshInit", () => gsap.set(".js-fadeinStagger", {y: 0}),{passive:true});
}

function hide(elem) {
  gsap.set(elem, {autoAlpha: 0, y: 0})
}

function init(){
  gsap.registerPlugin(ScrollTrigger)
  gsap.utils.toArray(".js-fadeinHero").forEach( setupFadeInFirstElement )
  gsap.utils.toArray(".js-fadein").forEach( setupFadeInElement )
  gsap.utils.toArray(".js-slidein-left").forEach(setupSlideInLeftElement)
  gsap.utils.toArray(".js-slidein-right").forEach(setupSlideInRightElement)
  gsap.utils.toArray(".js-slidein-up").forEach(setupSlideInUpElement)
  setupVerticalScroll()
  setupFadeInStaggerElements()
}

export default function initGreenSock(){
  $(document).ready( init )
}
