import $ from 'jquery'
const $doc = $(document)

function playPause(event) {
  let btn = $(this);
  let videoWrap = btn.parent().parent();
  let video = btn.parent().parent().find('video');

  event.preventDefault()
  video.addClass('test');

  if (videoWrap.hasClass('playing')) {
    video.trigger('pause');
    btn.attr('aria-label', 'Play Video');
  } else {
    video.trigger('play');
    btn.attr('aria-label', 'Pause Video');
  }
  
  videoWrap.toggleClass('playing');

}

export default function initDownButton(){
  $doc.on('click', '.js-video-play', playPause )
}